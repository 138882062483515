import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <h1>{`CSS Stats API`}</h1>
    <p>{`CSS Stats offers a JSON API that can be used in other tools. It's completely
`}
      <strong {...{
        "parentName": "p"
      }}>{`open source`}</strong>
      {` and `}
      <strong {...{
        "parentName": "p"
      }}>{`free to use`}</strong>
      {`. All responses are JSON objects.`}</p>
    <h2>{`Stats`}</h2>
    <h3><a {...{
        "href": "https://cssstats.com/api/stats?url=google.com",
        "parentName": "h3"
      }}><inlineCode {...{
          "parentName": "a"
        }}>{`/api/stats?url=google.com`}</inlineCode></a></h3>
    <p>{`The Stats endpoint is the primary API for CSS Stats. It takes a url query
parameter which is used to scrape all CSS and then return a stats object
based on the CSS it finds. This is what the app itself uses to populate
the CSS visualizations.`}</p>
    <h4>{`Shape of the returned object`}</h4>
    <pre><code {...{
        "className": "language-js",
        "parentName": "pre"
      }}>{`{
  stats: {},
  css: {
    links: [''],
    styles: [''],
    css: ''
  }
}
`}</code></pre>
    <p>{`Where the stats object has the following shape:`}</p>
    <pre><code {...{
        "className": "language-js",
        "parentName": "pre"
      }}>{`{
  size: n,
  gzipSize: n,
  rules: {
    total: n,
    size: {
      graph: [n],
      max: n,
      average: n
    }
  },
  selectors: {
    total: n,
    id: n,
    class: n,
    type: n,
    pseudoClass: n,
    psuedoElement: n,
    values: [str],
    specificity: {
      max: n
      average: n
    },
    getSpecificityGraph(),
    getSpecificityValues(),
    getRepeatedValues(),
    getSortedSpecificity()
  },
  declarations: {
    total: n,
    unique: n,
    important: [obj],
    properties:
      prop: [str]
    },
    getPropertyResets(),
    getUniquePropertyCount(),
    getPropertyValueCount(),
    getVendorPrefixed(),
    getAllFontSizes(),
    getAllFontFamilies(),
  },
  mediaQueries: {
    total: n,
    unique: n,
    values: [str],
    contents: [
      {
        value: str,
        rules: {
          total: n,
          size: {
            graph: [n],
            max: n,
            average: n
          }
        },
        selectors: {
          total: n,
          id: n,
          class: n,
          type: n,
          pseudoClass: n,
          pseudoElement: n,
          values: [str],
          specificity: {
            max: n,
            average: n
          }
        },
        declarations: {
          total: n,
          unique: n,
          important: [obj],
          vendorPrefix: n,
          properties: {
            prop: [str]
          }
        }
      }
    ]
  }
}
`}</code></pre>
    <h2>{`History`}</h2>
    <h3><a {...{
        "href": "https://cssstats.com/api/history?url=google.com&date=2010-01-01",
        "parentName": "h3"
      }}><inlineCode {...{
          "parentName": "a"
        }}>{`/api/history?url=google.com&date=2010-01-01`}</inlineCode></a></h3>
    <p>{`If you'd like to get a snapshot of CSS Stats for a url for
a particular date, you can use the History API.`}</p>
    <p>{`This API uses `}
      <a {...{
        "href": "https://archive.org/web/",
        "parentName": "p"
      }}>{`Archive.org's Wayback Machine`}</a>
      {`
so you must have a `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`robot.txt`}</inlineCode>
      {` that allows bots and the closest snapshot
for the given date will be used.`}</p>
    <p>{`Currently, all requests scrape directly from the Wayback Machine so
please throttle your requests so that everyone can use this service.`}</p>
    <p><strong {...{
        "parentName": "p"
      }}>{`The date must be formatted as YYYY-MM-DD`}</strong>
      {`.`}</p>
    <p>{`The returned JSON object is in the same shape as the Stats API.`}</p>
    <h2>{`CSS`}</h2>
    <h3><a {...{
        "href": "https://cssstats.com/api/css?url=google.com",
        "parentName": "h3"
      }}><inlineCode {...{
          "parentName": "a"
        }}>{`/api/css?url=google.com`}</inlineCode></a></h3>
    <p>{`If you only want to retrieve the CSS for a given url you can use `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`/api/css`}</inlineCode>
      {`.
It will return all the CSS links, style elements, and an aggregated CSS string.
The retrieval will follow CSS imports recursively.`}</p>
    <p>{`The endpoint will also return the page title and HTML string returned by the
URL.`}</p>
    <h3>{`Shape of the returned CSS object`}</h3>
    <pre><code {...{
        "className": "language-js",
        "parentName": "pre"
      }}>{`{
  links: [
    ''
  ]
  styles: [
    ''
  ],
  css: '',
  pageTitle: '',
  html: ''
}
`}</code></pre>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      